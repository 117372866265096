<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import {required} from "vuelidate/lib/validators";
import Vue from "vue";


/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ORDER LIST",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "OPEN ORDERS"
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      showRejectModel: false,
      submittedReject: false,
      reject:{
        orderId : "",
        rejectedReason: ""
      },
      fields: [
        { key: "images", sortable: false, label: "IMAGES",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "orderDate", sortable: true, label: "ORDER DETAILS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "firstName", sortable: true, label: "CUSTOMER",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "subTotal", sortable: true, label: "SUB TOTAL",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "discount", sortable: true, label: "DISCOUNT",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "total", sortable: true, label: "TOTAL",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "orderStatus", sortable: true, label: "ORDER STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION" ,  thStyle: { color: "black", "font-size":"16px"}}
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ordersData.length;
    }
  },
  validations: {
    reject:{
      rejectedReason: {required}
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.ordersData.length;
    Master.orderList({
    orderStatus:"1"
    }).then((res) => {
      console.log(res);
      this.ordersData = res.data.data;
    })
  },
  methods: {
    /**
     * Search the table data with search input
     */
    orderDetails(data){
      localStorage.setItem('orderId',data)
    },
    rejectModal(data){
      this.showRejectModel = true;
      this.reject.orderId = data
      this.reject.rejectedReason = ""
    },
    hideRejectModel(){
      this.showRejectModel = false
      this.submittedReject = false
      this.reject.rejectedReason= ""
    },
    orderReject(){
      Master.cancelTotalOrder(
              {
                orderId: this.reject.orderId,
                userId:1,
                cancelReason: this.reject.rejectedReason
              }).then(res => {
        console.log(res);

        Master.orderList({
          orderStatus:"1"
        }).then((res) => {
          console.log(res);
          this.ordersData = res.data.data;
        });


        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        // this.successMessage = res.data.message;
        this.showRejectModel = false
        this.submittedReject = false

      })


    },
    handleRejectedSubmit(){
      this.submittedReject = true;
      this.$v.$touch();
      if (this.$v.reject.$invalid) {
        return;
      } else {
        this.orderReject();
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Orders</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                      class="table-centered"
                      :items="ordersData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      striped
                      sort-icon-left
                  >
                   <template v-slot:cell(images)="image">

                      <div v-if="image.item.products[0] == null">
                        <router-link to="/open-order/order-details" >  <img
                            @click="orderDetails(image.item.id)"
                            src="../../../no-image.png"
                            class="b-avatar-lg"
                        /></router-link>
                      </div>
                     <div class="row" v-else>
                       <div class="col-md-2"  v-if="image.item.products.length > 1">
                         <router-link to="/open-order/order-details" ><img
                             @click="orderDetails(image.item.id)"
                             :src="image.item.products[0].imageUrl"
                             class="b-avatar-lg"
                         /> </router-link>
                       </div>
                       <div class="col-md-2"  v-else>
                         <router-link to="/open-order/order-details" > <img
                             :src="image.item.products[0].imageUrl"
                             @click="orderDetails(image.item.id)"
                             class="b-avatar-lg"
                         /></router-link>
                       </div>
                       <div class="col-md-4 ml-5 mt-3" v-if="image.item.products.length > 1">
                         + {{0 ? image.item.products : image.item.products.length - 1}}
                       </div>
                     </div>
                    </template>

                    <template v-slot:cell(orderStatus)="row">
                      <div v-if="row.item.orderStatus == 1"
                           class="badge font-size-12 badge-soft-success"
                      >CONFIRMED</div>
                      <div v-if="row.item.orderStatus == 2"
                           class="badge font-size-12 badge-soft-warning"
                      >PARTIAL DELIVERED</div>
                      <div v-if="row.item.orderStatus == 3"
                           class="badge font-size-12 badge-soft-info"
                      >DELIVERED ALL</div>
                      <div v-if="row.item.orderStatus == 4"
                           class="badge font-size-12 badge-soft-danger"
                      >CANCELLED</div>
                    </template>
                    <template v-slot:cell(firstName)="row">
                      <div class="badge font-size-12" style="text-transform: uppercase !important;">
                        {{row.item.firstName}} {{row.item.lastName}}
                      </div>
                    </template>
                    <template v-slot:cell(orderDate)="row">
                      <ul class="pl-1" style="list-style-type: none;">
                        <li>
                          <router-link to="/open-order/order-details" >  <div class="badge font-size-12" @click="orderDetails(row.item.id)">
                           {{row.item.orderNum}}
                          </div></router-link>
                        </li>
                        <li>
                          <div class="badge font-size-12">
                            QTY : {{row.item.qty}}
                          </div>
                        </li>
                        <li>
                          <div class="badge font-size-12">
                            {{ row.item.orderDate }}
                          </div>
                        </li>
                      </ul>
                    </template>
                    <template v-slot:cell(subTotal)="row">
                      <div class="badge font-size-12">
                        QAR {{row.item.subTotal.toLocaleString()}}
                      </div>
                    </template>
                    <template v-slot:cell(discount)="row">
                      <div class="badge font-size-12"  style="color: red">
                        QAR {{ (row.item.originalAmount - row.item.total).toLocaleString()}}
                      </div>
                    </template>
                    <template v-slot:cell(total)="row">
                      <div class="badge font-size-12">
                        QAR {{row.item.total.toLocaleString()}}
                      </div>
                    </template>
                    <template v-slot:cell(action) = "data">
                      <router-link to="/open-order/order-details"><a
                          @click="orderDetails(data.item.id)"
                          href="javascript:void(0);"
                          class="mr-3 text-warning"
                          v-b-tooltip.hover
                          title="VIEW"
                      >
                        <i class="mdi mdi-eye font-size-18"></i>
                      </a></router-link>
                      <a href="javascript:void(0);"
                         class="mr-2 text-danger"
                         @click="rejectModal(data.item.id)"
                         v-b-tooltip.hover
                         title="CANCEL"
                      >
                        <i class="mdi mdi-cancel font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
            id="modal-1"
            v-model="showRejectModel"
            title="ADD REJECTED REASON"
            title-class="text-dark font-18"
            hide-footer
            @close="hideRejectModel"
    >

      <form @submit.prevent="handleRejectedSubmit">
        <div class="form-group">
          <label for="name">REASON *</label>
          <textarea class="form-control" placeholder="ENTER REJECT REASON" v-model="reject.rejectedReason" :class="{ 'is-invalid': submittedReject   && $v.reject.rejectedReason.$error  }"></textarea>

          <div
                  v-if="submittedReject && !$v.reject.rejectedReason.required"
                  class="invalid-feedback"
          >REASON IS REQUIRED</div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>